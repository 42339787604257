
document.addEventListener("turbolinks:load", function(){
  const button = document.querySelector('#url');

  if (button) {
    button.addEventListener('click', function(){
      copy(document.querySelector('.url'));
    });
  }
})

function copy(el){
  navigator.clipboard.writeText(el.textContent)
  .then(() => {
    alert("コピーしたよ！招待しよう🤖")
  })
  .catch(err => {
    alert("コピーに失敗しました🤖")
  });
};
