<template>
  <div class="range">
    <input
      type="range"
      v-model="selectPoint"
      min="0"
      v-bind:max="this.selectMax"
      v-bind:name="inputName"
      class="input-range"
    >
    <p class="mt-20">
      選択投票ポイント: {{ selectPoint }}
    </p>
  </div>
</template>

<script defer>
  export default {
    data: function () {
      return {
        selectPoint: 0,
        selectMax: 100
      }
    },
    created() {
      if (document.getElementsByClassName("vue-component")[0].dataset.point) {
        this.selectPoint = document.getElementsByClassName("vue-component")[0].dataset.point;
      }
      if (document.getElementsByClassName("vue-component")[0].dataset.max) {
        this.selectMax = parseInt(this.selectPoint, 10) + parseInt(document.getElementsByClassName("vue-component")[0].dataset.max, 10);
      }
    },
    computed: {
      inputName: function () {
        if(location.pathname == "/practice_votes/new") {
          return "practice_vote[point]";
        }
        return "plan_vote[point]";
      }
    },
  }
</script>
